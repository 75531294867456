button {
    height: 50px;
}

.react-datepicker__input-container > input {
    padding: 17px;
    border-radius: 5px;
    border: 1px solid #717171;
}

.MuiDataGrid-root {
    border: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    border-bottom: 1px solid rgba(256, 256, 256, 2) !important;
    background: #e6eef9;
    color: #535353;
}

.MuiDataGrid-columnsContainer .MuiDataGrid-cell {
    background: transparent;
}

.MuiDataGrid-columnSeparator {
    visibility: hidden;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

.fullScreen {
    width: 100%;
    height: 100vh;
}
