@font-face {
    font-family: 'bpgweb';
    src: url('./assets/fonts/bpg-web-001-caps-webfont.woff2') format('woff2'),
        url('./assets/fonts/bpg-web-001-caps-webfont.woff') format('woff');
}

.MuiContainer-root {
    margin: 0;
}

.MuiAlert-message {
    display: flex;
    align-items: center;
}

.MuiAlert-icon {
    align-items: center;
}

p.error {
    color: #bf1650;
}

p.error::before {
    display: inline;
    content: '⚠ ';
}
